
import { Component, Vue, Prop } from "vue-property-decorator";
import { getRegions } from "@/api/components/select-address";

@Component({
  name: "SelectAddress",
})
export default class extends Vue {
  @Prop({ default: null }) form!: any;
  loading = true;
  provinces: any = [];
  citys: any[] = [];
  districts: any[] = [];

  created() {
    this.loadProvinces();
  }

  private async loadProvinces() {
    const result = await getRegions(0);
    this.provinces = result.data;
    if (this.form.provinceId) {
      const result = await getRegions(this.form.provinceId);
      this.citys = result.data;
    }
    if (this.form.cityId) {
      const result = await getRegions(this.form.cityId);
      this.districts = result.data;
    }
  }

  private async provinceChange() {
    if (this.form.provinceId) {
      const result = await getRegions(this.form.provinceId);
      this.citys = result.data;
      this.form.provinceName = this.provinces.find(
        (item: any) => item.id === this.form.provinceId
      ).fullName;
    } else {
      this.citys = [];
      this.form.provinceName = "";
    }
    this.districts = [];
    this.$set(this.form, "cityId", "");
    this.$set(this.form, "districtId", "");
    this.$emit("provinceChange", this.form.provinceId);
  }

  private async cityChange() {
    if (this.form.cityId) {
      const result = await getRegions(this.form.cityId);
      this.districts = result.data;
      this.form.cityName = this.citys.find(
        (item: any) => item.id === this.form.cityId
      ).fullName;
    } else {
      this.districts = [];
      this.form.cityName = "";
    }
    this.$set(this.form, "districtId", "");
    this.$emit("cityChange", this.form.cityId);
  }

  districtChange() {
    if (this.form.districtId) {
      this.form.districtName = this.districts.find(
        (item: any) => item.id === this.form.districtId
      ).fullName;
    } else {
      this.form.districtName = "";
    }
    this.$emit("districtChange", this.form.districtId);
  }
}
